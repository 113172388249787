












import { Component, Prop } from 'vue-property-decorator';
import VueBasePanelComponent from '@/components/panels/VueBasePanelComponent';

@Component
export default class PanelAsideComponent extends VueBasePanelComponent {
  @Prop({ required: false, default: '4' })
  private readonly size!: string;

  get customSize(): string {
    let { size } = this;
    const num = Number(size);
    if (!this.size || Number.isNaN(num) || num > 6) {
      size = '4';
    }

    return `col-xl-${size}`;
  }
}
